*, :before, :after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.collapse {
  visibility: collapse;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.-z-10 {
  z-index: -10;
}

.z-50 {
  z-index: 50;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-6, .h-\[1\.5rem\] {
  height: 1.5rem;
}

.h-\[1rem\] {
  height: 1rem;
}

.h-\[20\.5rem\] {
  height: 20.5rem;
}

.h-\[23\.5rem\] {
  height: 23.5rem;
}

.h-\[25\.5rem\] {
  height: 25.5rem;
}

.h-\[29\.943rem\] {
  height: 29.943rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[17\.5rem\] {
  max-height: 17.5rem;
}

.min-h-\[17\.5rem\] {
  min-height: 17.5rem;
}

.w-\[1\.5rem\] {
  width: 1.5rem;
}

.w-\[11\.5rem\] {
  width: 11.5rem;
}

.w-\[13\.25rem\] {
  width: 13.25rem;
}

.w-\[13\.5rem\] {
  width: 13.5rem;
}

.w-\[15\.42rem\] {
  width: 15.42rem;
}

.w-\[16\.5rem\] {
  width: 16.5rem;
}

.w-\[17\.35rem\] {
  width: 17.35rem;
}

.w-\[1rem\] {
  width: 1rem;
}

.w-\[20\.5rem\] {
  width: 20.5rem;
}

.w-\[40\.644rem\] {
  width: 40.644rem;
}

.w-\[full\] {
  width: full;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-16 {
  min-width: 4rem;
}

.min-w-6 {
  min-width: 1.5rem;
}

.min-w-fit {
  min-width: fit-content;
}

.max-w-6 {
  max-width: 1.5rem;
}

.max-w-\[144px\] {
  max-width: 144px;
}

.max-w-\[157px\] {
  max-width: 157px;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[1920px\] {
  max-width: 1920px;
}

.max-w-\[622px\] {
  max-width: 622px;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.grow {
  -moz-box-flex: 1;
  flex-grow: 1;
}

.-translate-x-\[11rem\] {
  --tw-translate-x: -11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[8\.625rem\] {
  --tw-translate-y: -8.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-col {
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-\[0\.625rem\] {
  border-radius: .625rem;
}

.rounded-\[1\.25rem\] {
  border-radius: 1.25rem;
}

.rounded-\[2\.5rem\] {
  border-radius: 2.5rem;
}

.border {
  border-width: 1px;
}

.border-b-\[0\.5px\] {
  border-bottom-width: .5px;
}

.border-solid {
  border-style: solid;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-whatsapp {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 222, 35, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-\[url\(\'\.\.\/assets\/images\/desktop\/corner\.svg\'\)\] {
  background-image: url("corner.abe94ce7.svg");
}

.bg-\[url\(\'\.\.\/assets\/images\/mobile\/icons\/carousel-arrow\.svg\'\)\] {
  background-image: url("carousel-arrow.01ecfba1.svg");
}

.bg-\[url\(\'\.\.\/assets\/images\/mobile\/icons\/star\.svg\'\)\] {
  background-image: url("star.d70178fc.svg");
}

.bg-\[url\(\'\.\.\/assets\/images\/mobile\/icons\/whatsapp\.svg\'\)\] {
  background-image: url("whatsapp.50152bc8.svg");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-gradient-l {
  --tw-gradient-from: #e65c00 var(--tw-gradient-from-position);
  --tw-gradient-to: #e65c0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-gradient-r {
  --tw-gradient-to: #f09819 var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-\[5\.5rem\] {
  padding-bottom: 5.5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl-1 {
  font-size: 35px;
}

.text-\[0\.625rem\] {
  font-size: .625rem;
}

.text-\[0\.9375rem\] {
  font-size: .9375rem;
}

.text-\[1\.75rem\] {
  font-size: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xxs {
  font-size: .625rem;
}

.font-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.leading-\[1\.1268rem\] {
  line-height: 1.1268rem;
}

.leading-\[1\.268rem\] {
  line-height: 1.268rem;
}

.leading-normal {
  line-height: 1.5;
}

.text-address {
  --tw-text-opacity: 1;
  color: rgba(230, 92, 0, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.shadow-\[4px_4px_4px_0_rgba\(0\,0\,0\,0\.25\)\] {
  --tw-shadow: 4px 4px 4px 0 #00000040;
  --tw-shadow-colored: 4px 4px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-\[200ms\] {
  transition-duration: .2s;
}

.duration-\[500ms\] {
  transition-duration: .5s;
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

@media (width <= 1536px) {
  .max-\[1536px\]\:hidden {
    display: none;
  }
}

@media not all and (width >= 1024px) {
  .max-lg\:hidden {
    display: none;
  }

  .max-lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .max-lg\:from-gradient-l {
    --tw-gradient-from: #e65c00 var(--tw-gradient-from-position);
    --tw-gradient-to: #e65c0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .max-lg\:to-gradient-r {
    --tw-gradient-to: #f09819 var(--tw-gradient-to-position);
  }
}

@media (width <= 1024px) {
  .max-\[1024px\]\:hidden {
    display: none;
  }
}

@media (width <= 1023px) {
  .max-\[1023px\]\:hidden {
    display: none;
  }
}

@media (width >= 1024px) {
  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-\[10\.5rem\] {
    margin-bottom: 10.5rem;
  }

  .lg\:mb-\[4\.5rem\] {
    margin-bottom: 4.5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mt-\[10\.5rem\] {
    margin-top: 10.5rem;
  }

  .lg\:mt-\[11\.56rem\] {
    margin-top: 11.56rem;
  }

  .lg\:mt-\[13\.43rem\] {
    margin-top: 13.43rem;
  }

  .lg\:mt-\[16\.875rem\] {
    margin-top: 16.875rem;
  }

  .lg\:mt-\[3\.5rem\] {
    margin-top: 3.5rem;
  }

  .lg\:mt-\[5\.0625rem\] {
    margin-top: 5.0625rem;
  }

  .lg\:mt-\[5\.6rem\] {
    margin-top: 5.6rem;
  }

  .lg\:mt-\[9\.5rem\] {
    margin-top: 9.5rem;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:h-\[1\.5rem\] {
    height: 1.5rem;
  }

  .lg\:h-\[25\.5rem\] {
    height: 25.5rem;
  }

  .lg\:h-\[27\.5rem\] {
    height: 27.5rem;
  }

  .lg\:h-\[28rem\] {
    height: 28rem;
  }

  .lg\:h-\[3\.687rem\] {
    height: 3.687rem;
  }

  .lg\:w-\[1\.5rem\] {
    width: 1.5rem;
  }

  .lg\:w-\[14rem\] {
    width: 14rem;
  }

  .lg\:w-\[22\.5rem\] {
    width: 22.5rem;
  }

  .lg\:w-\[22rem\] {
    width: 22rem;
  }

  .lg\:w-\[27\.75rem\] {
    width: 27.75rem;
  }

  .lg\:w-\[3\.687rem\] {
    width: 3.687rem;
  }

  .lg\:w-\[30\.68rem\] {
    width: 30.68rem;
  }

  .lg\:w-\[33\.125rem\] {
    width: 33.125rem;
  }

  .lg\:w-\[38\.3rem\] {
    width: 38.3rem;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-56 {
    max-width: 14rem;
  }

  .lg\:max-w-\[61\.68rem\] {
    max-width: 61.68rem;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:-translate-y-\[-3rem\] {
    --tw-translate-y: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-\[minmax\(min-content\,871px\)_minmax\(min-content\,min-content\)\] {
    grid-template-columns: minmax(min-content, 871px) minmax(min-content, min-content);
  }

  .lg\:grid-cols-\[minmax\(min-content\,891px\)_minmax\(min-content\,min-content\)\] {
    grid-template-columns: minmax(min-content, 891px) minmax(min-content, min-content);
  }

  .lg\:grid-rows-\[minmax\(min-content\,min-content\)_minmax\(min-content\,min-content\)\] {
    grid-template-rows: minmax(min-content, min-content) minmax(min-content, min-content);
  }

  .lg\:flex-row-reverse {
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    flex-direction: row-reverse;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-items-center {
    justify-items: center;
  }

  .lg\:gap-1 {
    gap: .25rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-18 {
    gap: 4.5rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-\[10\.81rem\] {
    gap: 10.81rem;
  }

  .lg\:gap-\[18\.45rem\] {
    gap: 18.45rem;
  }

  .lg\:gap-\[2px\] {
    gap: 2px;
  }

  .lg\:gap-\[5\.75rem\] {
    gap: 5.75rem;
  }

  .lg\:gap-\[6\.875rem\] {
    gap: 6.875rem;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:bg-whatsapp {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 222, 35, var(--tw-bg-opacity));
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-\[url\(\'\.\.\/assets\/images\/desktop\/icons\/whatsapp\.svg\'\)\] {
    background-image: url("whatsapp.e179de2a.svg");
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-\[12\.5rem\] {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
  }

  .lg\:px-\[5\.875rem\] {
    padding-left: 5.875rem;
    padding-right: 5.875rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:py-\[1\.4375rem\] {
    padding-top: 1.4375rem;
    padding-bottom: 1.4375rem;
  }

  .lg\:pb-\[12\.5rem\] {
    padding-bottom: 12.5rem;
  }

  .lg\:pb-\[14\.31rem\] {
    padding-bottom: 14.31rem;
  }

  .lg\:pb-\[4rem\] {
    padding-bottom: 4rem;
  }

  .lg\:pl-\[19rem\] {
    padding-left: 19rem;
  }

  .lg\:pr-2 {
    padding-right: .5rem;
  }

  .lg\:text-start {
    text-align: start;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-\[1\.5rem\] {
    font-size: 1.5rem;
  }

  .lg\:text-\[1\.875rem\] {
    font-size: 1.875rem;
  }

  .lg\:text-\[2\.18rem\] {
    font-size: 2.18rem;
  }

  .lg\:text-\[2\.5rem\] {
    font-size: 2.5rem;
  }

  .lg\:text-\[2\.812rem\] {
    font-size: 2.812rem;
  }

  .lg\:text-\[2\.8rem\] {
    font-size: 2.8rem;
  }

  .lg\:text-\[3\.125rem\] {
    font-size: 3.125rem;
  }

  .lg\:text-\[4\.375rem\] {
    font-size: 4.375rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:leading-\[1\.625rem\] {
    line-height: 1.625rem;
  }

  .lg\:leading-\[2\.505rem\] {
    line-height: 2.505rem;
  }

  .lg\:leading-\[3rem\] {
    line-height: 3rem;
  }

  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:shadow-\[4px_4px_4px_0_rgba\(0\,0\,0\,0\.25\)\] {
    --tw-shadow: 4px 4px 4px 0 #00000040;
    --tw-shadow-colored: 4px 4px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-\[4px_4px_4px_4px_rgba\(0\,0\,0\,0\.25\)\] {
    --tw-shadow: 4px 4px 4px 4px #00000040;
    --tw-shadow-colored: 4px 4px 4px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:bg-gradient-to-r:hover {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .lg\:hover\:from-gradient-l:hover {
    --tw-gradient-from: #e65c00 var(--tw-gradient-from-position);
    --tw-gradient-to: #e65c0000 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:hover\:to-gradient-r:hover {
    --tw-gradient-to: #f09819 var(--tw-gradient-to-position);
  }

  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .min-\[1024px\]\:hidden {
    display: none;
  }
}

@media (width >= 1280px) {
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:py-\[1\.4375rem\] {
    padding-top: 1.4375rem;
    padding-bottom: 1.4375rem;
  }

  .xl\:text-\[1\.625rem\] {
    font-size: 1.625rem;
  }

  .xl\:text-\[1\.75rem\] {
    font-size: 1.75rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:h-\[25\.5rem\] {
    height: 25.5rem;
  }

  .\32 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\32 xl\:px-\[19rem\] {
    padding-left: 19rem;
    padding-right: 19rem;
  }

  .\32 xl\:pl-\[22\.81rem\] {
    padding-left: 22.81rem;
  }

  .\32 xl\:leading-\[1\.9725rem\] {
    line-height: 1.9725rem;
  }
}

@media (width >= 1921px) {
  .min-\[1921px\]\:hidden {
    display: none;
  }
}
/*# sourceMappingURL=index.9c9f7420.css.map */
